<template>
  <div>
    <div
      class="rounded w-full relative transform duration-300 cursor-pointer hover:-translate-y-1"
      :style="{
        'background-image': 'url(' + deal.image + ')',
        ...style
      }"
      @click="$emit('selectVendor', deal.merchant)"
    >
      <div class="flex justify-between content-center p-6">
        <div class="col-span-1">
          <h3 class="text-13 text-white font-normal">
            Get
            <span class="text-5xl font-bold mr-1 pt-1">{{
              deal.credpal_commission_type === "percentage"
                ? `${deal.credpal_commission}%`
                : deal.credpal_commission
            }}</span
            >OFF
          </h3>
        </div>
        <div
          class="p-2 font-semibold text-center flex justify-center items-center h-8 w-28 rounded text-xs"
          style="background: #FFFCF0;"
          :style="{
            'background-image': 'url(' + deal.merchant.image + ')',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            'background-position': 'center'
          }"
        >
          <div v-if="!deal.merchant.image">
            {{ deal.merchant.business_name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    deal: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    style() {
      return {
        "background-size": "cover",
        "background-position": "center",
        "background-color": "#182041",
        "box-shadow": "inset 0 0 0 2000px rgba(24,32,65,0.20)"
      };
    }
  }
};
</script>
